import SchoolIcon from '@mui/icons-material/School';
import PetsIcon from '@mui/icons-material/Pets';
import ContactsIcon from '@mui/icons-material/Contacts';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

export const mathIconCategory: { [key: string]: React.ReactNode } = {
    'Educação e Pesquisa': <SchoolIcon />,
    'Saúde': <MonitorHeartIcon />,
    'Meio Ambiente e Produção Rural': <PetsIcon />,
    'Valorização do Servidor': <ContactsIcon />,
}