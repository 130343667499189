import React, { FC } from 'react';
import { TitleSection } from '../../../components/title-sections/title-sections';
import Slider from 'react-slick';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { Wave } from '../../../components/wave/wave';

export const GovPrograms: FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 100,
    cssEase: 'linear',
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const govPrograms = [
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_01.png`,
      alt: 'Programa Ronda do Bairro',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_02.png`,
      alt: 'Programa Vida Nova nas Grotas de Alagoas',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_03.png`,
      alt: 'Programa Cria Criança Alagoana',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_04.jpg`,
      alt: 'Programa Pró Estrada',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_01.png`,
      alt: 'Programa Ronda do Bairro',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-programs/program_02.png`,
      alt: 'Programa Vida Nova nas Grotas de Alagoas',
    },
  ];

  return (
    <div className="xvia-gov-programs__container">
      <Wave
        waveColor="var(--xvia-bg-gray)"
        backgroundColor="var(--xvia-color-darken-blue)"
      />
      <div className="xvia-container">
        <TitleSection
          titleSettings={{
            title: 'Programas do Governo',
            colorTitle: '#FFFFFF',
            subtitle: 'Conheça os nossos programas governamentais',
            colorSubtitle: '#D0D0D0',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <HandshakeIcon />,
            iconColor: '#FFFFFF',
            iconSize: '40px',
          }}
        />
        <div>
          <div className="xvia-gov-programs__container_gradient_final_slide">
            <div className="xvia-gov-programs__gradient_final_slide"></div>
          </div>
          <Slider {...settings}>
            {govPrograms.map((program, index) => {
              return (
                <div
                  key={index}
                  className="xvia-gov-programs__img_slide_container"
                >
                  <div className="xvia-gov-programs__img_slide">
                    <img src={program.img} alt={program.alt} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};
