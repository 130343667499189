import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FC, useState } from "react";
import { Link, LinkProps } from "./link";

export interface LinkContainerProps {
  text: string;
  color: string;
  icon: IconDefinition;
  onClick: () => void;
}
export const LinkContainer: FC<LinkContainerProps> = ({
  text,
  color,
  icon,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const props: Omit<LinkProps, "color" | "style"> = {
    text,
    onClick,
    icon,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };
  return (
    <Link
      color={isHovered ? "#fff" : color}
      style={{
        backgroundColor: isHovered ? color : "#fff",
        fontWeight: isHovered ? 700 : 500,
      }}
      {...props}
    />
  );
};
