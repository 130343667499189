import { FC, MouseEventHandler, useMemo } from 'react';
import { Title } from '../typography/title';
import { Paragraph } from '../typography/paragraph';
import { Rating } from '../rating/rating';
import { Info } from '../info/info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material';
import { designTokens } from '../../theme';

export interface ListingCardProps {
  title: string;
  subtitle: string;
  rating?: number;
  focused?: boolean;
  isFavourite?: boolean;
  isDigital?: boolean;
  cost?: string;
  type?: 'online' | 'in-person';
  disposition?: 'line' | 'card';
  department?: string;
  onClick: MouseEventHandler;
  onClickFavourite: MouseEventHandler;
  onClickOnlineService: MouseEventHandler;
}

const Card = styled(MuiCard)`
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${designTokens.palette.primary.main};
    transition: 0.35s;

    .xvia-listing-card__title {
      transition: 0.35s;
      font-weight: 600;
    }
  }
`;

export const ListingCard: FC<ListingCardProps> = ({
  title,
  subtitle,
  rating,
  type,
  isDigital,
  cost,
  department,
  onClick,
  onClickFavourite,
  onClickOnlineService,
}) => {
  const serviceTypeIcon = useMemo(
    () => (type === 'online' ? 'fa-globe-americas' : 'fa-location-dot'),
    [type]
  );

  const serviceTypeText = useMemo(
    () => (type === 'online' ? 'Online' : 'Presencial'),
    [type]
  );

  return (
    <Card>
      <div className="xvia-listing-card__content">
        <div className="xvia-listing-card__header">
          <Paragraph className="xvia-listing-card__department">
            <Info text={department || ''} icon="fa-building" />{' '}
          </Paragraph>
          <Paragraph className="xvia-listing-card__service-type">
            <Info text={serviceTypeText} icon={serviceTypeIcon} />{' '}
          </Paragraph>
        </div>
        <div className="xvia-listing-card__rating">
          {rating ? <Rating value={rating} readOnly={true} /> : null}
        </div>
        <Title className="xvia-listing-card__title" level={5}>
          {title}
        </Title>
        <Paragraph className="xvia-listing-card__subtitle">
          {subtitle}
        </Paragraph>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardOutlinedIcon />}
            size="small"
            onClick={onClick}
          >
            Ler completa
          </Button>
        </Box>
      </div>
    </Card>
  );
};
