import { FC } from "react";
import { SvgIconComponent } from "@mui/icons-material";

export interface IMidia {
  Icon: SvgIconComponent;
  link: string;
}

export interface SocialMidiaProps {
  midiasList: IMidia[];
}

export const SocialMidia: FC<SocialMidiaProps> = ({ midiasList }) => {
  return (
    <div className="xvia-social-midia">
      {midiasList.map(({ link, Icon }) => {
        return (
          <a
            className="xvia-social-midia__content_icon"
            href={link}
            target={"_blank"}
            rel="noreferrer"
            key={link}
          >
            <Icon className="xvia-social-midia__icon" />
          </a>
        );
      })}
    </div>
  );
};
