import React, { FC } from 'react';
import { Container } from '../../components/container/container';
import { Box } from '@mui/material';

interface ExtraSectionProps {
  children: React.ReactNode;
}

export const ExtraSection: FC<ExtraSectionProps> = ({ children }) => {
  return (
    <div className="xvia-catalog__extra-section-container">
      <Box sx={{ backgroundColor: 'var(--xvia-bg-gray)' }}>
        <Container>{children}</Container>
      </Box>
    </div>
  );
};
