import { FC } from 'react';
import { Element } from 'react-scroll';
import { SecretaryContainer } from '../modules/secretary/secretary.container';
import { FooterContainer } from '../modules/footer/footer.container';

export const Secretary: FC = () => {
  return (
    <Element name="recommendedServices">
      <SecretaryContainer />
      <FooterContainer />
    </Element>
  );
};
