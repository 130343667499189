import { Box } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

type WaveProps = {
  waveColor: string;
  backgroundColor: string;
  height?: string;
};

export const Wave = ({
  waveColor,
  backgroundColor,
  height = '100px',
}: WaveProps) => (
  <Box
    className="xvia-wave"
    style={
      {
        '--before-color': waveColor,
        backgroundColor,
        '--before-height': height,
      } as CSSProperties
    }
  ></Box>
);
