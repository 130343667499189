import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabConfig {
  title: string;
  icon?: React.ReactNode
  iconPosition?: 'top' | 'start' | 'end' | 'bottom';
  content: React.ReactNode;
}

interface TabsContentProps {
  tabs: TabConfig[];
  onTabChange?: (index: number) => void;
}

export const TabsContent: React.FC<TabsContentProps> = ({ tabs, onTabChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );

  const iconConfigs = (index: number) => {
    const tab = tabs[index];
    return {
      ...(tab.icon ? { icon: tab.icon } : {}),
      ...(tab.iconPosition ? { iconPosition: tab.iconPosition } : {}),
    };
  };

  interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  interface StyledTabProps {
    label: string;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      centered
      {...props}
      className="xvia-gov-news__tabs"
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#FF891B',
    },
  });

  const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props}  className="xvia-gov-news__tab"/>
  ))(({ theme }) => ({
    fontWeight: 700,
    fontSize: '20px',
    color: '#9E9E9E',
  }));
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs">
          {tabs.map((tab, index) => {
            return (
              <StyledTab
                key={index}
                label={tab.title}
                {...iconConfigs(index)}
                {...a11yProps(index)}
              />
            )
          })}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TabsContent;
