import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  KeyboardEventHandler,
  useCallback,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import styleModule from './search-bar.module.css';
import {
  Button,
  SearchBarBlock,
  SearchBarContainer,
  SearchBarInput,
  SearchBarInputContainer,
} from './style';

export interface SearchInputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  onClickSearch: any;
  onChangeText: (text: string) => void;
}

export const SearchBar: FC<SearchInputProps> = ({
  className,
  onChangeText,
  onClickSearch,
  value,
  placeholder = 'Buscar...',
  ...inputProps
}) => {
  const textChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;

      if (value !== newValue) {
        onChangeText(newValue);
      }
    },
    [onChangeText, value]
  );

  const keyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter') {
        onClickSearch(event);
      }
    },
    [onClickSearch]
  );

  return (
    <SearchBarContainer>
      <SearchBarBlock>
        <SearchBarInputContainer>
          <SearchIcon
            style={{
              color: '#BBBBBB',
              width: '20px',
              height: '20px',
            }}
          />
          <span>|</span>
          <SearchBarInput
            onChange={textChangeHandler}
            onKeyDown={keyDownHandler}
            value={value}
            type="text"
            name="keyword"
            aria-label="Campo de busca"
            placeholder={placeholder}
            {...inputProps}
          />
        </SearchBarInputContainer>
        <Button onClick={onClickSearch} variant="contained" size="small">
          Procurar
        </Button>
      </SearchBarBlock>
    </SearchBarContainer>
  );
};
