import React, { FC, useCallback, useEffect, useState } from 'react';
import { VideoContent } from './video-content';
import { getVideoNews } from '../../../../../apis/news';
import { NewsMediaItem } from '../all-content/all-content.container';
import { reorganizeNews } from '../../../../../util/news';

export type VideoNewsItem = Omit<NewsMediaItem, 'image' | 'text'>;
export type VideoNewsContent = Record<string, VideoNewsItem[]>;

interface VideoContentContainerProps {
  reorganizeVideoNews?: boolean;
  directionCardColumns?: 'row' | 'column';
}

export const VideoContentContainer: FC<VideoContentContainerProps> = ({
  reorganizeVideoNews,
  directionCardColumns,
}) => {
  const [newsVideoList, setNewsVideoList] = useState<VideoNewsContent>({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getNewsServer = useCallback(() => {
    getVideoNews()
      .then(setNewsVideoList)
      .catch((err) => {
        console.log(err);
      });
  }, [setNewsVideoList]);

  useEffect(() => {
    Object.keys(newsVideoList).length === 0 && getNewsServer();

    if (Object.keys(newsVideoList).length > 0) {
      const total = Object.keys(newsVideoList).length;
      setTotalPages(total);
    }
  }, [newsVideoList, getNewsServer, setTotalPages]);

  useEffect(() => {
    if (reorganizeVideoNews) {
      const reorderItens = reorganizeNews(newsVideoList);
      if (JSON.stringify(reorderItens) !== JSON.stringify(newsVideoList)) {
        setNewsVideoList(reorderItens);
      }
    }
  }, [reorganizeVideoNews, newsVideoList]);

  return (
    <VideoContent
      directionCardColumns={directionCardColumns}
      videoProps={{
        newsVideoList,
        page,
        totalPages,
        handleChange,
      }}
    />
  );
};
