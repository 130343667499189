import React from 'react';

const EmailIcon: React.FC<{
  width?: string | number;
  height?: string | number;
  color?: string;
}> = ({ width = 41, height = 41, color = '#FF891B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 0H36.75C38.7812 0 40.5 1.71875 40.5 3.75C40.5 5 39.875 6.09375 38.9375 6.79688L21.9844 19.5312C21.0469 20.2344 19.875 20.2344 18.9375 19.5312L1.98438 6.79688C1.04688 6.09375 0.5 5 0.5 3.75C0.5 1.71875 2.14062 0 4.25 0ZM0.5 8.75L17.4531 21.5625C19.25 22.8906 21.6719 22.8906 23.4688 21.5625L40.5 8.75V25C40.5 27.8125 38.2344 30 35.5 30H5.5C2.6875 30 0.5 27.8125 0.5 25V8.75Z"
        fill={color}
      />
    </svg>
  );
};

export default EmailIcon;
