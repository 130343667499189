import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import { styled, Typography } from '@mui/material';

export const Card = styled(MuiCard)`
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);
  display: flex;

  @media screen and (max-width: 990px) {
    margin: 20px 0 !important;
  }
`;

export const CardContent = styled(MuiCardContent)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 !important; // verificar no inspecionar elemento qual

  @media screen and (max-width: 700px) {
    height: 100% !important;
    display: block;
  }
`;

export const Box = styled(MuiBox)`
  width: 100%;
`;

export const BoxCardIconInfo = styled(MuiBox)`
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 10px 0 0 0;
  }

  @media screen and (max-width: 700px) {
    flex-direction: row;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const BoxImage = styled(MuiBox)`
  justify-content: center;
  display: flex;
  height: 100%;
  align-items: center;
  background-color: #7b7b7b;
  margin: 0 15px 0 0;
  color: #fff;
  padding: 30px;

  > svg {
    display: flex;
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px;
  }
`;

export const BoxText = styled(MuiBox)`
  @media screen and (max-width: 700px) {
    margin: 10px;
  }
`;

export const BoxChevronCard = styled(MuiBox)`
  display: flex;
  margin: 15px;

  @media screen and (max-width: 1200px) {
    margin: 0;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const GridContainerCards = styled(MuiGrid)`
  @media screen and (max-width: 990px) {
    display: contents;
  }
`;

export const CardIconInfo = styled(MuiBox)`
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;

  > svg {
    color: #ff891b;
  }

  > p {
    text-align: left;
    font-size: 14px;
    font-family: Roboto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 10px;
    width: 110px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
`;

export const Text = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  font-family: Roboto;
  color: #202020;
  margin: 10px 0;

  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const ContainerPagination = styled(MuiBox)`
  justify-content: center;
  margin: 50px 0;
  align-items: center;
  display: flex;
`;
