import React, { FC } from 'react';
import { SecretaryCardList } from './secretary-card-list';
import { SecretaryCard } from '../secretary.container';

export interface SecretaryCardListContainerProps {
  cards: SecretaryCard[];
}

export const SecretaryCardListContainer: FC<SecretaryCardListContainerProps> =
  ({ cards }) => {
    const redirect = (link: string) => {
      window.open(link, '_blank');
    };

    return <SecretaryCardList cards={cards} redirect={redirect} />;
  };
