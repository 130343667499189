import React, { FC, Fragment } from 'react';
import EastIcon from '@mui/icons-material/East';

import { Pagination } from '../../../../../components';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';

import { mathIconCategory } from '../../../../../util/matchAplicationIcons';

import {
  Box,
  BoxCardIconInfo,
  BoxImage,
  BoxText,
  Button,
  Card,
  CardActions,
  CardContent,
  CardIconInfo,
  CardText,
  CardTitle,
  ContainerPagination,
  GridCards,
  GridContainer,
} from './style';

export interface GalleryNewsItem {
  title: string;
  text: string;
  category: string;
  date: string;
  image: string;
  imageAlt: string;
}

export type GalleryNewsContent = Record<string, GalleryNewsItem[]>;

interface GalleryNewsContentProps {
  galleryNewsList: GalleryNewsContent;
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const GalleryContent: FC<GalleryNewsContentProps> = ({
  galleryNewsList,
  page,
  totalPages,
  handleChange,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridContainer container spacing={2}>
        {Object.keys(galleryNewsList).length > 0 && (
          <>
            {galleryNewsList[`${page}`].map((item, index) => (
              <Fragment key={index}>
                <GridCards item xs={6}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <BoxImage>
                        <img src={item.image} alt={item.imageAlt} />
                      </BoxImage>
                      <BoxText>
                        <BoxCardIconInfo>
                          <CardIconInfo>
                            <TodayIcon />
                            <p>{item.date}</p>
                          </CardIconInfo>
                          <CardIconInfo>
                            {mathIconCategory[item.category] || (
                              <CategoryIcon />
                            )}
                            <p>{item.category}</p>
                          </CardIconInfo>
                        </BoxCardIconInfo>

                        <CardTitle>{item.title}</CardTitle>

                        <CardText>{item.text}</CardText>
                      </BoxText>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="contained">
                        Ler completa
                        <EastIcon />
                      </Button>
                    </CardActions>
                  </Card>
                </GridCards>
              </Fragment>
            ))}
          </>
        )}
      </GridContainer>

      <ContainerPagination>
        <Pagination count={totalPages} onChange={handleChange} page={page} />
      </ContainerPagination>
    </Box>
  );
};
