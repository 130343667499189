import { FC } from 'react';
import { Col, Empty, Row } from 'antd';
import { Container } from '../../components/container/container';
import { ListingCard } from '../../components/cards/listing-card';
import { Catalog } from '../../apis/search-catalog';
import striptags from 'striptags';
import Button from '@mui/material/Button';
import { TitleSection } from '../../components/title-sections/title-sections';
import QuestionAnswerRounded from '@mui/icons-material/QuestionAnswerRounded';
import { SearchFilterBtn } from '../secretary/style';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Category, CategoryFilter } from './list.container';
import { Pagination } from '../../components';
import { PageBanner } from '../../components/page-banner/page-banner';
import Accordion from '../../components/card-accordion/card-accordion';
import { Info } from '@mui/icons-material';

export interface ListProps {
  list?: Catalog[];
  onClick: (catalog: Catalog) => void;
  onClickFavourite: (slug: string) => void;
  onClickOnlineService: (link: string) => void;
  page: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  toggleDrawer: (newOpen: boolean) => () => void;
  selectedCategories: CategoryFilter[];
  handleCheckboxChange: (filter: CategoryFilter) => void;
  handleFilterByCategory: () => void;
  categories: Category[];
  open: boolean;
  resultTotal?: number;
  totalPages: number;
  disposition: 'line' | 'card';
}

export const List: FC<ListProps> = ({
  list,
  onClick,
  onClickFavourite,
  onClickOnlineService,
  page,
  handleChange,
  toggleDrawer,
  selectedCategories,
  handleCheckboxChange,
  handleFilterByCategory,
  categories,
  open,
  disposition,
  resultTotal,
  totalPages,
}) => {
  const DrawerList = (
    <Box sx={{ p: 2 }} role="presentation">
      <Box sx={{ textAlign: 'end' }} role="presentation">
        <Button
          onClick={toggleDrawer(false)}
          style={{
            background: 'transparent',
            color: '#000',
          }}
          endIcon={<CloseIcon sx={{ width: '20px' }} />}
        ></Button>
      </Box>
      <TitleSection
        titleSettings={{
          title: 'Filtro',
          colorTitle: '#202020',
          fontSizeTitle: '16px',
          subtitle: 'Selecione os filtros que deseja ver abaixo',
          colorSubtitle: '#7B7B7B',
          fontSizeSubtitle: '14px',
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <FilterAltIcon />,
          iconColor: '#9E9E9E',
          iconSize: '2x',
        }}
      />
      {categories && (
        <FormGroup>
          {categories.map(({ id, name, slug }) => (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={selectedCategories.some((item) => item.id === id)}
                  onChange={() => handleCheckboxChange({ id, name, slug })}
                />
              }
              label={name}
            />
          ))}
        </FormGroup>
      )}
      <Box sx={{ textAlign: 'end', p: 3 }} role="presentation">
        <Button variant="contained" onClick={() => handleFilterByCategory()}>
          Filtrar
        </Button>
      </Box>
    </Box>
  );
  return (
    <>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
      <div className="xvia-list">
        <PageBanner
          bannerTitle={`Cartas de Serviços (${resultTotal || 0})`}
          bannerImage={`${process.env.PUBLIC_URL}/images/banner/banner_letters_services.png`}
        />
        <Container className="xvia-list__container">
          <div
            className="xvia-list__header-container"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <TitleSection
              titleSettings={{
                title: 'Leia nossa Carta de Serviços',
                colorTitle: '#202020',
                subtitle:
                  'Tem alguma dúvida? Aqui é onde você irá encontrar sua resposta',
                colorSubtitle: '#7B7B7B',
              }}
              iconSettings={{
                displayIcon: true,
                sectionIcon: <QuestionAnswerRounded />,
                iconColor: '#9E9E9E',
                iconSize: '2x',
              }}
            />
            <SearchFilterBtn>
              <Button
                onClick={toggleDrawer(true)}
                variant="text"
                endIcon={<FilterAltIcon />}
              >
                Filtrar
              </Button>
            </SearchFilterBtn>
          </div>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Accordion
              children="A Carta de Serviços é um instrumento institucional e de cidadania que visa dar transparência aos serviços oferecidos pelos órgãos públicos. Sua missão é fazer com que o cidadão possa acessar os serviços de forma simples, dinâmica, e prática. 
Neste contexto, o X-Via Digital cria condições para que os órgãos e entidades da administração pública estadual já disponham das informações necessárias para a disponibilização da “Carta de Serviços ao Usuário”, corroborada pela referida Lei nº 13.460/2017, em seu Art. 7º, que diz que, os órgãos e entidades abrangidos por esta Lei divulgarão Carta de Serviços ao Usuário. 
Aqui você pode conferir as Cartas de Serviços ao Cidadão  dos órgãos entidades da administração pública estadual."
              title='O que é uma "Carta de Serviço"?'
              subTitle="(Clique para ampliar a resposta)"
              icon={<Info />}
            />
          </Box>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Row gutter={[16, 16]}>
                {list?.map((catalog) => {
                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={disposition === 'line' ? 24 : 12}
                      lg={disposition === 'line' ? 24 : 12}
                      xl={disposition === 'line' ? 24 : 12}
                      xxl={disposition === 'line' ? 24 : 12}
                      key={catalog.title}
                      style={{ marginBottom: 0 }}
                    >
                      <ListingCard
                        title={catalog.title}
                        subtitle={striptags(catalog.description)}
                        type={catalog.isOnline ? 'online' : 'in-person'}
                        onClick={() => {
                          onClick(catalog);
                        }}
                        onClickFavourite={() => {
                          onClickFavourite(catalog.slug);
                        }}
                        onClickOnlineService={() => {
                          onClickOnlineService(catalog.link);
                        }}
                        disposition={disposition}
                        isDigital={catalog.isDigital}
                        department={catalog.department}
                      />
                    </Col>
                  );
                })}
              </Row>
              {!list?.length && (
                <Empty description={'Nenhum serviço encontrado'} />
              )}
              <Pagination
                count={totalPages}
                onChange={handleChange}
                page={page}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
