import React from 'react';

const TalkBallonIcon: React.FC<{
  width?: string | number;
  height?: string | number;
  color?: string;
}> = ({ width = 48, height = 48, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 27.5C14.2031 27.5 11.4688 26.875 9.04688 25.7031C8.42188 26.0156 7.79688 26.25 7.09375 26.5625C5.60938 27.1094 3.89062 27.5 2.25 27.5C1.70312 27.5 1.23438 27.1875 1 26.6406C0.84375 26.1719 1.07812 25.625 1.46875 25.3125V25.2344H1.54688C1.625 25.1562 1.78125 25 1.9375 24.9219C2.17188 24.6875 2.5625 24.2969 2.95312 23.8281C3.34375 23.2812 3.8125 22.5781 4.04688 21.875C2.09375 19.6094 1 16.7969 1 13.75C1 6.17188 8.26562 0 17.25 0C26.1562 0 33.5 6.17188 33.5 13.75C33.5 21.4062 26.1562 27.5 17.25 27.5ZM36 13.75H35.9219C35.9219 13.3594 35.9219 12.9688 35.9219 12.5781C44.3594 13.0469 50.9219 19.0625 50.9219 26.25C50.9219 29.2969 49.8281 32.1094 47.875 34.375C48.1094 35.0781 48.5781 35.7031 48.9688 36.25C49.3594 36.7969 49.75 37.1094 49.9844 37.4219C50.1406 37.5 50.2969 37.6562 50.375 37.6562C50.375 37.7344 50.4531 37.7344 50.4531 37.7344C50.9219 38.125 51.0781 38.6719 50.9219 39.1406C50.7656 39.6875 50.2188 40 49.75 40C48.0312 40 46.3125 39.6094 44.8281 39.0625C44.125 38.75 43.5 38.5156 42.875 38.2031C40.4531 39.375 37.7188 40 34.75 40C27.25 40 20.9219 35.7812 19.0469 29.9219C28.1875 29.1406 36 22.5781 36 13.75Z"
        fill={color}
      />
    </svg>
  );
};

export default TalkBallonIcon;
