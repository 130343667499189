import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CategoryIcon from '@mui/icons-material/Category';
import CloseIcon from '@mui/icons-material/Close';
import { TitleSection } from '../../components/title-sections/title-sections';
import { SearchBar } from '../../components/search-bar/search-bar';
import { CategoryFilter, SecretaryCard, Category } from './secretary.container';
import { PageBanner } from '../../components/page-banner/page-banner';
import { SecretaryCardListContainer } from './secretary-card-list/secretary-card-list.container';
import { ContainerPageActions, FilterTitle } from './style';
import { Pagination } from '../../components';

export interface SecretaryCardListProps {
  cards: SecretaryCard[];
  categories: Category[];
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  toggleDrawer: (newOpen: boolean) => () => void;
  selectedCategories: CategoryFilter[];
  handleCheckboxChange: (filter: CategoryFilter) => void;
  handleFilterByCategory: () => void;
  open: boolean;
  onClickSearch: () => void;
  onChangeText: (text: string) => void;
  value: string;
}

export const Secretary: FC<SecretaryCardListProps> = ({
  cards,
  categories,
  totalPages,
  handleChange,
  page,
  toggleDrawer,
  selectedCategories,
  handleCheckboxChange,
  handleFilterByCategory,
  open,
  onClickSearch,
  onChangeText,
  value,
}) => {
  const DrawerList = (
    <Box sx={{ p: 2 }} role="presentation">
      <Box sx={{ textAlign: 'end' }} role="presentation">
        <Button
          onClick={toggleDrawer(false)}
          style={{
            background: 'transparent',
            color: '#000',
          }}
          endIcon={<CloseIcon sx={{ width: '20px' }} />}
        ></Button>
      </Box>
      <TitleSection
        titleSettings={{
          title: 'Filtro',
          colorTitle: '#202020',
          fontSizeTitle: '16px',
          subtitle: 'Selecione os filtros que deseja ver abaixo',
          colorSubtitle: '#7B7B7B',
          fontSizeSubtitle: '14px',
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <FilterAltIcon style={{ margin: '0 10px' }} />,
          iconColor: '#9E9E9E',
          iconSize: '30px',
          iconMargin: '0px 10px',
        }}
      />
      <FilterTitle>Categorias</FilterTitle>
      <FormGroup>
        {categories.map(({ id, name }) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                checked={selectedCategories.some((item) => item.id === id)}
                onChange={() => handleCheckboxChange({ id, name })}
              />
            }
            label={name}
          />
        ))}
      </FormGroup>
      <Box sx={{ textAlign: 'end', p: 3 }} role="presentation">
        <Button variant="contained" onClick={() => handleFilterByCategory()}>
          Filtrar
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>

      <PageBanner
        bannerTitle="Secretarias e Órgãos (69)"
        bannerImage={`${process.env.PUBLIC_URL}/images/banner/banner_secretary.png`}
      />

      <div className="xvia-container">
        <TitleSection
          titleSettings={{
            title: 'Estrutura Organizacional',
            colorTitle: '#202020',
            subtitle: 'Conheça aqui todos os nossos Órgãos e Secretarias',
            colorSubtitle: '#7B7B7B',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <CategoryIcon />,
            iconColor: '#9E9E9E',
            iconSize: '50px',
          }}
          buttonSeeMoreSettings={{
            buttonLabel: 'Ver Organograma',
            buttonIcon: <VisibilityIcon />,
            customButtonStyle: {
              textTransform: 'capitalize',
            },
          }}
        />
        <ContainerPageActions>
          <SearchBar
            placeholder="Digite o que procura..."
            onClickSearch={onClickSearch}
            onChangeText={onChangeText}
            value={value}
            aria-label="Campo de busca de secretarias"
          />
        </ContainerPageActions>

        <div>
          <SecretaryCardListContainer cards={cards} />
        </div>
        <Pagination count={totalPages} onChange={handleChange} page={page} />
      </div>
    </>
  );
};
