import { FC } from "react";

export interface BannerProps {
  imageBanner?: string;
}
export const Banner: FC<BannerProps> = ({
  imageBanner,
}) => {
  return (
    <div
      className="xvia-banner__container"
    >
      <div
        className="xvia-banner"
        style={{
          backgroundImage: `url("${imageBanner}")`,
        }}
      >
      </div>
    </div>
  );
};
