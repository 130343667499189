import React, { FC } from 'react';
import { Button } from '@mui/material';
import styleModule from './title-section.module.css';

export interface TitleSectionProps {
  iconSettings: {
    displayIcon: boolean;
    sectionIcon?: JSX.Element;
    iconColor?: string;
    iconSize?: string;
    iconMargin?: string;
  };
  titleSettings: {
    title: string;
    colorTitle?: string;
    fontSizeTitle?: string;
    fontWeightTitle?: string;
    subtitle: string;
    colorSubtitle?: string;
    fontSizeSubtitle?: string;
    fontWeightSubtitle?: string;
  };
  buttonSeeMoreSettings?: {
    buttonLabel: string;
    buttonIcon?: JSX.Element;
    customButtonStyle?: React.CSSProperties;
  };
}

export const TitleSection: FC<TitleSectionProps> = ({
  iconSettings,
  titleSettings,
  buttonSeeMoreSettings,
}) => {
  return (
    <div className={styleModule['xvia-title-section__container']}>
      <div className={styleModule['xvia-title-section__block_title']}>
        <div className={styleModule['xvia-title-section__bar']} />
        {iconSettings?.sectionIcon &&
          React.cloneElement(iconSettings.sectionIcon, {
            style: {
              color: iconSettings.iconColor || '#000',
              width: iconSettings.iconSize || '40px',
              height: iconSettings.iconSize || '40px',
              margin: iconSettings.iconMargin || '0px 20px',
            },
          })}
        <div className={styleModule['xvia-title-section__text_content']}>
          <p
            style={{
              color: titleSettings?.colorTitle || '#202020',
              fontSize: titleSettings?.fontSizeTitle || '26px',
              fontWeight: titleSettings?.fontWeightTitle || '500',
            }}
          >
            {titleSettings.title}
          </p>
          <span
            style={{
              color: titleSettings?.colorSubtitle || '#7b7b7b',
              fontSize: titleSettings?.fontSizeSubtitle || '20px',
              fontWeight: titleSettings?.fontWeightSubtitle || '500',
            }}
          >
            {titleSettings.subtitle}
          </span>
        </div>
      </div>
      <div className={styleModule['xvia-title-section__block_button']}>
        {buttonSeeMoreSettings && (
          <Button
            variant="contained"
            style={{ ...buttonSeeMoreSettings.customButtonStyle }}
          >
            {buttonSeeMoreSettings?.buttonIcon &&
              buttonSeeMoreSettings.buttonIcon}
            {buttonSeeMoreSettings.buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
