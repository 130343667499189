import axios from 'axios';

const apiUrl = process.env.BACKEND_ENDPOINT;

export interface Catalog {
  type: string;
  title: string;
  slug: string;
  description: string;
  tags: string[];
  isFree: boolean;
  isOnline: boolean;
  isDigital?: boolean;
  category: string;
  targets: string[];
  department: string;
  departmentSections: string[];
  categorySlug: string;
  highlighted: boolean;
  score: number;
  imageUrl: string;
  icon: string;
  link: string;
  resultTotal: number;
}

interface params {
  accessToken?: string;
  query?: string;
  category?: string[];
  departmentSlug?: string;
  from?: number;
  max?: number;
}

export async function search({
  accessToken,
  query,
  category,
  departmentSlug,
  from,
  max,
}: params): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search`,
    {
      query,
      groups: ['CATALOG'],
      category,
      departmentSlug,
      from,
      max,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
