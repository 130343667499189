import { FC, useCallback } from 'react';
import { ChevronRight, SvgIconComponent } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

export interface LinkListItem {
  text: string;
  Icon?: SvgIconComponent;
  link: string;
  isExternal?: boolean;
}
export interface LinkListProps {
  title: string;
  Icon: SvgIconComponent;
  linkList: LinkListItem[];
}

export const LinkList: FC<LinkListProps> = ({
  title,
  Icon: TitleIcon,
  linkList,
}) => {
  const history = useHistory();

  const onClickExternalLink = (link: string) => {
    window.open(link, '_blank');
  };

  const onClick = useCallback(
    (to: string) => {
      history.push(to);
    },
    [history]
  );

  return (
    <nav
      aria-label={`Links de navegação para ${title}`}
      className="xvia-link-list"
    >
      <dl className="xvia-link-list__ul">
        <dt className="xvia-link-list__title">
          <TitleIcon />
          <span>{title}</span>
        </dt>
        {linkList.map(({ text, Icon, link, isExternal }, index) => {
          return (
            <dd key={index}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  isExternal ? onClickExternalLink(link) : onClick(link);
                }}
                href={isExternal ? link : undefined}
                target={isExternal ? '_blank' : undefined}
                rel={isExternal ? 'noreferrer' : undefined}
                className="xvia-link-list__text"
                aria-label={`${text} - Abre em uma nova aba`}
              >
                {Icon ? (
                  <Icon style={{ marginRight: '8px' }} />
                ) : (
                  <ChevronRight
                    style={{
                      transform: 'translate(-9px)',
                      marginRight: '-1.21px',
                    }}
                  />
                )}
                {text}
              </a>
            </dd>
          );
        })}
      </dl>
    </nav>
  );
};
