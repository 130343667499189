import {
  KeyboardArrowRight,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import React, { FC, useState } from 'react';

export interface IconTextProps {
  icon: React.ReactNode;
  text: string;
  action?: () => void;
}

export const IconText: FC<IconTextProps> = ({ icon, text, action }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="xvia-icon-text__container"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={action}
    >
      <div className="xvia-icon-text__icon">{icon}</div>
      <div className="xvia-icon-text__text">{text}</div>
      {action && (
        <div className="xvia-icon-text__icon-action">
          {hovered ? <KeyboardDoubleArrowRight /> : <KeyboardArrowRight />}
        </div>
      )}
    </div>
  );
};
