import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faFolder } from "@fortawesome/free-solid-svg-icons";

export interface ServiceCardProps {
  description: string;
  onClick: any;
  targets: string[];
  title: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  description,
  onClick,
  targets,
  title,
}) => {
  return (
    <div aria-label={`Acessar ${title}`} className="xvia-service-card">
      <div className="xvia-service-card__content">
        <div className="xvia-service-card__targets">
          {targets.map((target, index) => (
            <div key={index}>
              <FontAwesomeIcon icon={faFolder} color="#FF891B" />
              {target}
            </div>
          ))}
        </div>
        <h4>{title}</h4>
        <div className="xvia-service-card__description">{description}</div>
      </div>
      <footer>
        <button onClick={onClick}>
          Ler Completa <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </footer>
    </div>
  );
};
