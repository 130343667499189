import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { LinkContainer } from "../../modules/link/link.container";

export interface LinkBarProps {
  linkList: {
    text: string;
    onClick: () => void;
    icon: IconDefinition;
  }[];
  color: string;
}

export const LinkBar: FC<LinkBarProps> = ({ linkList, color }) => {
  return (
    <div
      className="xvia-link-bar"
      role="navigation"
      aria-label="Barra de links"
    >
      {linkList.map(({ text, onClick, icon }) => (
        <LinkContainer
          key={text}
          text={text}
          color={color}
          icon={icon}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
