import axios from 'axios';
import { Catalog } from './search-catalog';

const apiUrl = process.env.BACKEND_ENDPOINT;

export async function getRelevant(accessToken?: string): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search`,
    {
      groups: ['CATALOG'],
      highlighted: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getTopCatalog(accessToken?: string): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search`,
    {
      top_hit: 4,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
