import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface InfoProps {
  text: string;
  icon: string;
  color?: string;
}

export const Info: FC<InfoProps> = ({ text, icon }) => {
  return (
    <span className={'xvia-info'} aria-label={`${text}`}>
      <FontAwesomeIcon
        className={'xvia-info__icon'}
        icon={`fa-solid ${icon}` as IconProp}
        style={{ color: '#FF891B' }}
      />

      <span className={'xvia-info__text'}>{text}</span>
    </span>
  );
};
