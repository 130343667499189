import { FC } from 'react';
import { LinkBar, LinkBarProps } from '../../components/link-bar/link-bar';
import { Wave } from '../../components/wave/wave';

export const Welcome: FC<LinkBarProps> = (linkBarProps) => (
  <>
    <div className="xvia-welcome">
      <img
        src={`${process.env.PUBLIC_URL}/images/xvia-logo.png`}
        alt="logo da X-Via"
        width={100}
      />
      <div className="xvia-welcome__text">
        <h1>Bem vindo!</h1>
        <span>Navegue no nosso portal:</span>
      </div>
      <LinkBar {...linkBarProps} />
    </div>
    <Wave
      waveColor="var(--xvia-color-primary)"
      backgroundColor="var(--xvia-bg-gray)"
    />
  </>
);
