import React, { FC, useCallback, useEffect, useState } from 'react';
import { Secretary, SecretaryCardListProps } from './secretary';
import {
  getAvailableSecretarysCategories,
  getSecretarys,
} from '../../apis/secretary';

export interface SecretaryCard {
  name: string;
  image: string;
  location?: string;
  date?: string;
  phone1: string;
  phone2: string;
  link: string;
}

export interface Category {
  description: string;
  hidden: boolean;
  icon: string;
  id: string;
  name: string;
  orderIndex: number;
  slug: string;
}

export type CategoryFilter = Pick<Category, 'id' | 'name'>;

export const SecretaryContainer: FC = () => {
  const [secretaryList, setSecretaryList] = useState<SecretaryCard[]>([]);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [filteredSecretaryList, setFilteredSecretaryList] = useState<
    SecretaryCard[]
  >([]);

  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryFilter[]
  >([]);

  const onSearchBySecretary = useCallback(
    (search: string) => {
      if (!search.trim()) {
        setFilteredSecretaryList(secretaryList);
        return;
      }

      getSecretarys(page, search).then((response) => {
        setSecretaryList(response.cardsData);
        setTotalPages(response.totalPages);
      });
    },
    [secretaryList, page]
  );

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const onClickSearch = useCallback(() => {
    onSearchBySecretary(value);
  }, [value, onSearchBySecretary]);

  const onChangeText = useCallback((text: string) => {
    setValue(text);
  }, []);

  const handleCheckboxChange = ({ id, name }: CategoryFilter) => {
    setSelectedCategories((prev) => {
      const exists = prev.find((item) => item.id === id);

      if (exists) {
        return prev.filter((item) => item.id !== id);
      }

      return [...prev, { id, name }];
    });
  };

  const handleFilterByCategory = () => {
    setOpen(false);
    onFilterByCategory(selectedCategories);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    getSecretarys(newPage, value).then((response) => {
      setSecretaryList(response.cardsData);
      setTotalPages(response.totalPages);
    });
    setPage(newPage);
  };

  const onFilterByCategory = (selectedCategories: CategoryFilter[]) => {
    console.log(selectedCategories);
  };

  useEffect(() => {
    let ignore = false;

    setCategoryList([]);

    getAvailableSecretarysCategories().then((result) => {
      if (!ignore) {
        setCategoryList(result);
      }
    });

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    setFilteredSecretaryList(secretaryList);
  }, [secretaryList]);

  useEffect(() => {
    getSecretarys(1).then((response) => {
      setSecretaryList(response.cardsData);
      setTotalPages(response.totalPages);
    });
  }, []);

  const secretaryViewProps: SecretaryCardListProps = {
    cards: filteredSecretaryList,
    categories: categoryList,
    totalPages,
    handleChange,
    page,
    toggleDrawer,
    selectedCategories,
    handleCheckboxChange,
    handleFilterByCategory,
    open,
    onClickSearch,
    onChangeText,
    value,
  };

  return <Secretary {...secretaryViewProps} />;
};
