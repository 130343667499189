import React, { FC } from "react";
import { TitleSection } from "../../../components/title-sections/title-sections";
import {
  faBullseye,
  faComputer,
  faUser,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import MouseIcon from '@mui/icons-material/Mouse';

export const ServicePortalData: FC = () => {
  const serviceData = [
    {
      icon: faBullseye,
      name: "Serviços no Portal",
      quant: "836",
    },
    {
      icon: faComputer,
      name: "Serviços Digitais",
      quant: "235",
    },
    {
      icon: faUser,
      name: "Cadastros no Portal",
      quant: "33.256",
    },
    {
      icon: faThumbsUp,
      name: "Avaliação Positiva",
      quant: "98%",
    },
  ];

  return (
    <div className="xvia-container">
      <TitleSection
        titleSettings={{
          title: "Dados do nosso Portal",
          colorTitle: "#202020",
          subtitle: "",
          colorSubtitle: "",
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <MouseIcon />,
          iconColor: "#9E9E9E",
          iconSize: "40px",
        }}
      />

      <div className="xvia-service-portal-data__container-items">
        {serviceData.map((service, index) => {
          return (
            <div key={index} className="xvia-service-portal-data__block">
              {service.icon && (
                <>
                  <FontAwesomeIcon
                    color="#FF891B"
                    className="fa-4x"
                    icon={service.icon as IconProp}
                  />
                </>
              )}
              <div className="xvia-service-portal-data__text_block_icons">
                <span>{service.name}</span>
                <p>{service.quant}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
