import { FC } from 'react';
import { Element } from 'react-scroll';
import { FooterContainer } from '../modules/footer/footer.container';
import { BannerContainer } from '../modules/banner/banner.container';
import { WelcomeContainer } from '../modules/welcome/welcome.container';
import { ServiceCardsContainer } from '../modules/service-cards/service-cards.container';
import { GovProgramsContainer } from '../modules/home/gov-programs/gov-programs.container';
import { ServicePortalDataContainer } from '../modules/home/service-portal-data/service-portal-data.container';
import { GovNewsContainer } from '../modules/home/gov-news/gov-news.container';
import { ContactUsContainer } from '../modules/contact-us/contact-us.container';

export const Home: FC = () => {
  return (
    <>
      <BannerContainer />
      <WelcomeContainer />
      <ServiceCardsContainer />
      <ContactUsContainer />
      <Element name="govNews" className="xvia-gov-news__container">
        <GovNewsContainer />
      </Element>
      <Element
        name="servicePortalData"
        className="xvia-service-portal-data__container"
      >
        <ServicePortalDataContainer />
      </Element>
      <Element name="GovPrograms" className="xvia-gov-programs__wrapper">
        <GovProgramsContainer />
      </Element>
      <FooterContainer />
    </>
  );
};
