import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface DividerEncapsulatedProps {
  children: ReactNode;
  dividerHeight?: string;
  dividerColor?: string;
  backgroundColor?: string;
  hasDividerBottom?: boolean;
}

export const DividerEncapsulated: FC<DividerEncapsulatedProps> = ({
  children,
  dividerHeight = '120px',
  dividerColor = 'var(--xvia-bg-gray)',
  backgroundColor = 'var(--xvia-color-darken-blue)',
  hasDividerBottom = true,
}) => {
  return (
    <Box
      className="divider-encapsulated-wrapper"
      style={{ position: 'relative' }}
    >
      <Box
        className="divider-encapsulated__divider-top"
        style={{
          height: dividerHeight,
          backgroundColor: dividerColor,
        }}
      ></Box>

      <Box
        className="divider-encapsulated__content"
        style={{
          position: 'relative',
          zIndex: 1,
          marginTop: `calc(${dividerHeight} / 2)`,
          backgroundColor: backgroundColor,
          clipPath: hasDividerBottom ? 'ellipse(100% 80% at 50% 20%)' : 'none',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
