import { FunctionComponent } from 'react';
import { Home } from './routes/Home';
import { Secretary } from './routes/Secretary';
import { ServiceList } from './routes/ServiceList';
import { Catalog } from './routes/Catalog';

interface IRoutes {
  path: string;
  component: FunctionComponent<any>;
  exact?: boolean;
}

export const ROUTES = {
  HOME_PAGE: '/',
  DEPARTMENT_LIST_PAGE: '/secretarias-e-orgaos',
  DEPARTMENT_PAGE: '/secretarias-e-orgaos/:department',
  SERVICE_LIST_PAGE: '/carta-de-servicos',
  SEARCH_SERVICE_PAGE: '/search/:slug',
  SERVICE_PAGE: '/carta-de-servicos/:service',
  NEWS_PAGE: '/noticias',
  PUBLIC_INFO_PAGE: '/informacoes-publicas',
  DEFAULT_PAGE: '*',
};

export const routes: IRoutes[] = [
  { path: ROUTES.HOME_PAGE, component: Home, exact: true },
  { path: ROUTES.DEPARTMENT_LIST_PAGE, component: Secretary },
  { path: ROUTES.SERVICE_LIST_PAGE, component: ServiceList, exact: true },
  { path: ROUTES.SEARCH_SERVICE_PAGE, component: ServiceList },
  { path: ROUTES.SERVICE_PAGE, component: Catalog },
  { path: ROUTES.DEFAULT_PAGE, component: Home },
];
