import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { FC } from 'react';
import { LinkBar, LinkBarProps } from '../../components/link-bar/link-bar';
import {
  FilteredServicesPanelContainer,
  FilterEnum,
} from '../filtered-services-panel/filtered-services-panel.container';
import { TitleSection } from '../../components/title-sections/title-sections';
import { Visibility } from '@mui/icons-material';

export interface ServiceCardsProps {
  linkBarProps: LinkBarProps;
  filter?: FilterEnum | null;
  animationClass: string;
  timeoutId: NodeJS.Timeout | number | null;
}

export const ServiceCards: FC<ServiceCardsProps> = ({
  linkBarProps,
  filter,
  animationClass,
  timeoutId,
}) => (
  <>
    <div className="xvia-service-cards">
      <TitleSection
        titleSettings={{
          title: 'Serviços e Cartas de Serviços',
          colorTitle: '#202020',
          subtitle:
            'Serviços e Cartas de Serviços mais acessadas pela população',
          colorSubtitle: '#7B7B7B',
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <AccountTreeIcon />,
          iconColor: '#9E9E9E',
          iconSize: '2x',
        }}
        buttonSeeMoreSettings={{
          buttonLabel: 'Ver todas',
          buttonIcon: <Visibility />,
        }}
      />
      <span>
        Selecione uma das principais categorias abaixo para ver as cartas de
        serviço da mesma
      </span>
      <LinkBar {...linkBarProps} />
      {filter && (
        <FilteredServicesPanelContainer
          filter={filter}
          animationClass={animationClass}
          timeoutId={timeoutId}
        />
      )}
    </div>
  </>
);
