import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiButton from '@mui/material/Button';

export const ContainerPageBanner = styled(MuiBox)`
  display: flex;
  weight: 100%;
  min-height: 250px;
  align-items: start;
  clip-path: ellipse(60% 75% at 50% 20%);

  svg {
    color: #fff;
    width: 50px;
    height: 50px;
  }

  h2 {
    color: #fff;
    font-size: 50px;
    font-family: Roboto Serif;
    font-weight: 600;
    margin: 0px;
  }

  @media screen and (max-width: 800px) {
    min-height: 150px;

    > h2 {
      font-size: 22px;
    }
  }
`;

export const ContainerTitle = styled(MuiBox)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    > h2 {
      font-size: 22px;
    }
  }
`;

export const Button = styled(MuiButton)`
  > svg {
    color: #fff;
    width: 50px;
    height: 50px;
  }
`;
