import { useEffect } from 'react';
import './App.css';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { routes } from './routes';
import { usePageTrackingGA, useWebgate } from '@xvia/webgate-connect';

function App() {
  const location = useLocation();
  const history = useHistory();
  const { portalMessages } = useWebgate();

  usePageTrackingGA(location);

  useEffect(() => {
    if (portalMessages?.detail?.pushRoute) {
      history.push(portalMessages.detail.pushRoute);
    }
  }, [history, portalMessages]);

  return (
    <Switch>
      {routes.map(({ component: RouteComponent, path, exact }) => (
        <Route key={path} path={path} exact={exact}>
          <RouteComponent />
        </Route>
      ))}
    </Switch>
  );
}

export default App;
