import axios from 'axios';
import Agent from 'agentkeepalive';

const apiUrl = process.env.BACKEND_ENDPOINT;

export interface ICategory {
  id: string;
  slug: string;
  name: string;
  description: string;
  hidden: boolean;
  icon: string;
  orderIndex: number;
  __categories__?: string[];
  __has_categories__?: boolean;
}

const keepAliveAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});

const axiosInstance = axios.create({ httpAgent: keepAliveAgent });

export async function getCategorys(): Promise<ICategory[]> {
  const response = await axiosInstance.get(`${apiUrl}/v1/category`);

  return response.data;
}
