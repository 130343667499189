import {
  faCarSide,
  faGlobe,
  faGraduationCap,
  faHeartbeat,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { LinkBarProps } from '../../components/link-bar/link-bar';
import { FilterEnum } from '../filtered-services-panel/filtered-services-panel.container';
import { ServiceCards } from './service-cards';

export const ServiceCardsContainer = () => {
  const [filter, setFilter] = useState<FilterEnum | null>(null);
  const [isClosingFilteredList, setIsClosingFilteredList] =
    useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | number | null>(
    null
  );

  const closeFilteredList = () => {
    setIsClosingFilteredList(true);
    const timeoutIdTmp = setTimeout(() => setFilter(null), 500);
    setTimeoutId(timeoutIdTmp);
  };

  const openFilteredList = (filter: FilterEnum) => {
    setIsClosingFilteredList(false);
    setFilter(filter);
  };

  const props: LinkBarProps = {
    color: '#005EA9',
    linkList: [
      {
        text: 'Principais',
        icon: faGlobe,
        buttonFilter: FilterEnum.MAIN,
      },
      {
        text: 'Trânsito, Veículos\ne Habilitação',
        icon: faCarSide,
        buttonFilter: FilterEnum.CAR,
      },
      {
        text: 'Saúde e\nVigilância Sanitária',
        icon: faHeartbeat,
        buttonFilter: FilterEnum.HEALTH,
      },
      {
        text: 'Segurança\nPública',
        icon: faShieldAlt,
        buttonFilter: FilterEnum.SECURITY,
      },
      {
        text: 'Educação\ne Pesquisa',
        icon: faGraduationCap,
        buttonFilter: FilterEnum.RESEARCH,
      },
    ].map(({ buttonFilter, ...args }) => ({
      ...args,
      onClick: () =>
        filter === buttonFilter
          ? closeFilteredList()
          : openFilteredList(buttonFilter),
    })),
  };

  return (
    <ServiceCards
      linkBarProps={props}
      filter={filter}
      timeoutId={timeoutId}
      animationClass={`xvia-filtered-services-panel__container--${
        isClosingFilteredList ? 'closing' : 'opening'
      }`}
    />
  );
};
