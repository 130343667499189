import { useEffect, useState } from "react";
import { Banner } from "./banner";

export const BannerContainer = () => {
  const [bannerIndex, setBannerIndex] = useState<number>(1);

  useEffect(() => {
    const timeout = setTimeout(
      () => setBannerIndex((prevIndex) => (prevIndex % 3) + 1),
      2000
    );
    return () => clearTimeout(timeout);
  }, [bannerIndex]);

  return (
    <Banner
      imageBanner={`${process.env.PUBLIC_URL}/images/banner/${bannerIndex}.png`}
    />
  );
};
