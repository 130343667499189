import React, { FC } from 'react';

interface InfoCreateProps {
  icon: React.ReactNode;
  text: string;
}

export interface BoxSquareProps {
  infoCreate: InfoCreateProps[];
  title: string;
  description: string;
  button?: React.ReactNode;
}

export const BoxSquare: FC<BoxSquareProps> = ({
  infoCreate,
  title,
  description,
  button,
}) => {
  return (
    <div className="xvia-box-square__container">
      {infoCreate.length > 0 && (
        <div className="xvia-box-square__info-create">
          {infoCreate.map((info) => (
            <div key={info.text} className="xvia-box-square__info-create-item">
              {info.icon}
              {info.text}
            </div>
          ))}
        </div>
      )}
      <div>
        <div className="xvia-box-square__title">{title}</div>
        <div className="xvia-box-square__description">{description}</div>
      </div>
      <div>{button}</div>
    </div>
  );
};
