import React, { FC } from 'react';
import { AllContent } from './all-content';

export interface NewsItem {
  title: string;
  category: string;
  date: string;
  text: string;
}

export interface NewsMediaItem extends NewsItem {
  image: string;
  imageAlt: string;
  imageVideo: string;
}

export const AllContentContainer: FC = () => {
  return <AllContent />;
};
