import { Phone, SvgIconComponent } from '@mui/icons-material';
import { FC } from 'react';
import { LinkList, LinkListProps } from '../../components/link-list/link-list';
import {
  IMidia,
  SocialMidia,
} from '../../components/social-midia/social-midia';
import { Wave } from '../../components/wave/wave';

export interface FooterProps {
  lists: LinkListProps[];
  social: IMidia[];
  contacts: { Icon: SvgIconComponent; text: string }[];
}

export const Footer: FC<FooterProps> = ({ lists, social, contacts }) => {
  return (
    <>
      <div className="xvia-footer">
        <div className="xvia-footer__logo">
          <img
            src={`${process.env.PUBLIC_URL}/images/xvia-white.png`}
            alt="Logo do Governo de São Paulo"
            width={170}
          />
          <SocialMidia midiasList={social} />
        </div>
        <hr />
        <div className="xvia-footer__links">
          {lists?.map(({ title, Icon, linkList }, index) => (
            <LinkList
              title={title}
              Icon={Icon}
              linkList={linkList}
              key={index}
            />
          ))}
        </div>
        <div className="xvia-footer__contact">
          <div className="xvia-link-list__title">
            <Phone />
            <span>Contato</span>
          </div>
          <div className="xvia-footer__contact__list">
            {contacts.map(({ Icon, text }, index) => (
              <div key={index}>
                <Icon />
                <span>{text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Wave
        waveColor="var(--xvia-color-darkness-blue)"
        backgroundColor="#06214c"
      />
      <div className="xvia-footer__footer">
        <a
          href={'https://www.xvia.com.br'}
          target={'_blank'}
          rel="noreferrer"
          aria-label="Visite o site da X-VIA"
        >
          Criado e desenvolvido por X-VIA
        </a>
      </div>
    </>
  );
};
