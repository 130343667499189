import { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography } from '@mui/material';
import { ContainerCardImg, ContainerCards } from './style';

interface SecreataryCard {
  name: string;
  image: string;
  location?: string;
  date?: string;
  phone1: string;
  phone2: string;
  link: string;
}

interface SecretaryCardListProps {
  cards: SecreataryCard[];
  redirect: (link: string) => void;
}

export const SecretaryCardList: FC<SecretaryCardListProps> = ({
  cards,
  redirect,
}) => {
  return (
    <ContainerCards>
      {cards.map((value, index) => (
        <Card
          key={index}
          sx={{ maxWidth: { md: '48%', sm: '100%' }, height: '100%' }}
        >
          <CardContent
            sx={{
              display: 'flex',
            }}
          >
            <ContainerCardImg>
              <img src={value.image} alt="" />
            </ContainerCardImg>
            <div>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  fontFamily: "'Roboto Serif', serif",
                  color: '#202020',
                }}
              >
                {value.name}
              </Typography>
              {value?.location && (
                <Box display="flex" alignItems="flex-start" gap={1} mt={1}>
                  <LocationOnIcon style={{ color: '#FF891B' }} />
                  <Typography
                    sx={{
                      fontFamily: "'Roboto Serif', serif",
                      color: '#7B7B7B',
                    }}
                  >
                    {value.location}
                  </Typography>
                </Box>
              )}
              {value?.date && (
                <Box display="flex" alignItems="flex-start" gap={1} mt={1}>
                  <AccessTimeIcon style={{ color: '#FF891B' }} />
                  <span>{value.date}</span>
                </Box>
              )}
              <Box display="flex" alignItems="flex-start" mt={1}>
                {value?.phone1 && (
                  <Box display="flex" alignItems="flex-start" gap={1}>
                    <PhoneIcon style={{ color: '#FF891B' }} />
                    <span>{value.phone1}</span>
                  </Box>
                )}
                {value?.phone2 && (
                  <Box display="flex" alignItems="flex-start" gap={1}>
                    <PhoneIcon style={{ color: '#FF891B' }} />
                    <span>{value.phone2}</span>
                  </Box>
                )}
              </Box>
            </div>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => redirect(value.link)}
              size="small"
              variant="contained"
              endIcon={<LogoutIcon />}
            >
              Ir para o site
            </Button>
          </CardActions>
        </Card>
      ))}
    </ContainerCards>
  );
};
