import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const ContainerCards = styled(MuiBox)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const ContainerCardImg = styled(MuiBox)`
  margin: 0 20px 0 0;
  border: 2px solid #d0d0d0;
  padding: 10px;
  border-radius: 10px;
  width: 125px;
  height: 120px;

  > img {
    width: 105px;
    height: 100px;
    object-fit: contain;
  }
`;
