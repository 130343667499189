import { FC } from 'react';
import {
  AccessTimeFilled,
  AccountTreeOutlined,
  CalendarTodayOutlined,
  Email,
  FacebookRounded,
  FlagOutlined,
  ForumOutlined,
  HomeOutlined,
  Instagram,
  LaptopOutlined,
  LocationOn,
  Logout,
  MarkEmailReadOutlined,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import { Footer, FooterProps } from './footer';
import { ROUTES } from '../../routes';

export const FooterContainer: FC = () => {
  const props: FooterProps = {
    social: [
      {
        Icon: FacebookRounded,
        link: '',
      },
      {
        Icon: Twitter,
        link: '',
      },
      {
        Icon: Instagram,
        link: '',
      },
      {
        Icon: YouTube,
        link: '',
      },
    ],
    lists: [
      {
        title: 'Navegue dentro do portal',
        Icon: LaptopOutlined,
        linkList: [
          {
            text: 'Início',
            Icon: HomeOutlined,
            link: '',
          },
          {
            text: 'Nosso Governo',
            Icon: FlagOutlined,
            link: '',
          },
          {
            text: 'Secretarias e Órgãos',
            Icon: AccountTreeOutlined,
            link: ROUTES.DEPARTMENT_LIST_PAGE,
          },
          {
            text: 'Carta de Serviços',
            Icon: MarkEmailReadOutlined,
            link: ROUTES.SERVICE_LIST_PAGE,
          },
          {
            text: 'SIC - Serviços de Informação ao Cidadão',
            Icon: ForumOutlined,
            link: '',
          },
          {
            text: 'Agenda do Governador',
            Icon: CalendarTodayOutlined,
            link: '',
          },
        ],
      },
      {
        title: 'Links Externos',
        Icon: Logout,
        linkList: [
          {
            text: 'Seduc - Secretaria de Estado de Educação',
            link: '',
            isExternal: true,
          },
          {
            text: 'Seplag - Secretaria de Estado do Planejamento, Gestão e Patrimônio',
            link: '',
            isExternal: true,
          },
          {
            text: 'Sefaz - Secretaria de Estado da Fazenda',
            link: '',
            isExternal: true,
          },
          {
            text: 'Setrand - Secretaria de Estado do Transporte e Desenvolvimento Urbano',
            link: '',
            isExternal: true,
          },
          {
            text: 'Sesau - Secretaria de Estado da Saúde',
            link: '',
            isExternal: true,
          },
          {
            text: 'PGE - Procuradoria Geral do Estado de São Paulo',
            link: '',
            isExternal: true,
          },
          {
            text: 'CRIA - Secretaria de Estado da primeira Infância',
            link: '',
            isExternal: true,
          },
          {
            text: 'Diário Oficial',
            link: '',
            isExternal: true,
          },
          {
            text: 'Gabinete Civil',
            link: '',
            isExternal: true,
          },
          {
            text: 'Atos Normativos',
            link: '',
            isExternal: true,
          },
          {
            text: 'Informações Consolidadas',
            link: '',
            isExternal: true,
          },
          {
            text: 'Ouvidoria do Estado de São Paulo',
            link: '',
            isExternal: true,
          },
          {
            text: 'Portal de Acesso à Informação - LAI',
            link: '',
            isExternal: true,
          },
          {
            text: 'Portal da Transparência - Graciliano Ramos',
            link: '',
            isExternal: true,
          },
        ],
      },
    ],
    contacts: [
      {
        Icon: LocationOn,
        text: 'Av. Sagitário, 138 Alphaville\nCEP: 06473-073, Barueri/SP',
      },
      {
        Icon: AccessTimeFilled,
        text: 'Atendimento:\nSegunda a Sexta, das 8hrs as 17hrs',
      },
      {
        Icon: Email,
        text: 'contato@xvia.com.br',
      },
    ],
  };

  return <Footer {...props} />;
};
