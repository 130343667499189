import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, FC } from "react";

export interface LinkProps {
  text: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  icon: IconDefinition;
  color: string;
  style: CSSProperties;
}

export const Link: FC<LinkProps> = ({
  text,
  icon,
  color,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <button
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={style}
  >
    <FontAwesomeIcon icon={icon} size="3x" color={color} />
    <div>{text}</div>
  </button>
);
