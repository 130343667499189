import React, { FC, useCallback, useEffect, useState } from 'react';
import { MaterialContent } from './material-content';
import { getNews } from '../../../../../apis/news';
import { NewsMediaItem } from '../all-content/all-content.container';

export type MaterialNewsItem = Omit<NewsMediaItem, 'imageVideo'>;
export type MaterialNewsContent = Record<string, MaterialNewsItem[]>;

export const MaterialContentContainer: FC = () => {
  const [newsList, setNewsList] = useState<MaterialNewsContent>({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getNewsServer = useCallback(() => {
    getNews()
      .then(setNewsList)
      .catch((err) => {
        console.log(err);
      });
  }, [setNewsList]);

  useEffect(() => {
    Object.keys(newsList).length === 0 && getNewsServer();

    if (Object.keys(newsList).length > 0) {
      const total = Object.keys(newsList).length;
      setTotalPages(total);
    }
  }, [newsList, getNewsServer, setTotalPages]);

  return (
    <MaterialContent
      newsList={newsList}
      page={page}
      totalPages={totalPages}
      handleChange={handleChange}
    />
  );
};
