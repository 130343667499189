import React from 'react';

const PhoneIcon: React.FC<{
  width?: string | number;
  height?: string | number;
  color?: string;
}> = ({ width = 41, height = 41, color = '#FF891B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3125 2.95312L16.4375 10.4531C16.9844 11.7031 16.6719 13.1875 15.5781 14.0469L11.75 17.25C14.3281 22.7188 18.7812 27.1719 24.25 29.75L27.4531 25.9219C28.3125 24.8281 29.7969 24.5156 31.0469 25.0625L38.5469 28.1875C40.0312 28.7344 40.7344 30.375 40.3438 31.8594L38.4688 38.7344C38.0781 40.0625 36.9062 41 35.5 41C16.125 41 0.5 25.375 0.5 6C0.5 4.59375 1.4375 3.42188 2.76562 3.03125L9.64062 1.15625C11.125 0.765625 12.7656 1.46875 13.3125 2.95312Z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
