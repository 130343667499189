import { styled } from '@mui/material';

import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardActions from '@mui/material/CardActions';
import MuiButton from '@mui/material/Button';
import MuiTypography from '@mui/material/Typography';

export const Box = styled(MuiBox)``;

export const Grid = styled(MuiGrid)``;

export const GridContainer = styled(MuiGrid)`
  flex-direction: row;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const GridCards = styled(MuiGrid)`
  @media screen and (max-width: 700px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Card = styled(MuiCard)`
  height: 100%;
`;

export const CardContent = styled(MuiCardContent)`
  display: block;
  padding: 0;
  margin: 0;
`;

export const CardActions = styled(MuiCardActions)`
  justify-content: end;

  svg {
    margin-left: 10px;
  }
`;

export const Button = styled(MuiButton)``;

export const BoxImage = styled(MuiBox)`
  img {
    width: 100%;
  }
`;

export const BoxText = styled(MuiBox)`
  padding: 10px 20px;
`;

export const BoxCardIconInfo = styled(MuiBox)`
  display: flex;
`;

export const CardIconInfo = styled(MuiBox)`
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;

  > svg {
    color: #ff891b;
    margin-right: 10px;
  }

  > p {
    text-align: left;
    font-size: 14px;
    font-family: Roboto Serif;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
    margin: 0;
  }
`;

export const CardTitle = styled(MuiTypography)`
  font-weight: 500;
  font-size: 18px;
  font-family: Roboto Serif;
  color: #202020;
  margin: 10px 0;
`;

export const CardText = styled(MuiTypography)`
  font-weight: 500;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto Serif;
  color: #7b7b7b;
`;

export const ContainerPagination = styled(MuiBox)`
  justify-content: center;
  margin: 50px 0;
  align-items: center;
  display: flex;
`;
