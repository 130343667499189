import React, { FC, MouseEventHandler } from 'react';
import { CatalogDetail } from '../../apis/detail-catalog';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Container } from '../../components/container/container';
import { Paragraph } from '../../components/typography/paragraph';
import { Col, Row } from 'antd';
import { Step } from '../../components/step/step';
// @ts-ignore
import renderHTML from 'react-render-html';
import { Info } from '../../components/info/info';
import striptags from 'striptags';
import { BreadcrumbItem } from '../../components/breadcrumb/breadcrumb';
import { ContactUsContainer } from '../contact-us/contact-us.container';
import { PageBanner } from '../../components/page-banner/page-banner';
import { Box, Button } from '@mui/material';
import {
  AccountTree,
  CalendarMonth,
  FormatListBulleted,
  InfoOutlined,
  LocationOn,
  Logout,
  Person,
  Work,
} from '@mui/icons-material';
import { BoxSquare } from '../../components/box/box-square/box-square';
import { IconText } from '../../components/icon-text/icon-text';
import Accordion from '../../components/card-accordion/card-accordion';
import { ExtraSection } from '../../components/extra-sectiom/extra-section';
import { TitleSection } from '../../components/title-sections/title-sections';
import { ServiceCard } from '../../components/cards/service-card';
import Location from '../../components/location/location';

export interface CatalogProps extends CatalogDetail {
  onPdfClick?: MouseEventHandler;
  onFavoriteClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  breadcrumbs: BreadcrumbItem[];
}

export const Catalog: FC<CatalogProps> = ({
  name,
  link,
  isFree,
  isOnline,
  updated_at,
  onPdfClick,
  onFavoriteClick,
  onServiceClick,
  description,
  info,
  steps,
  targets,
  tags,
  department,
  category,
  departmentSections,
  averageRatings,
  id,
  breadcrumbs,
}) => {
  return (
    <div className="xvia-catalog">
      <PageBanner
        bannerTitle="Detalhes do Serviço"
        breadcrumbs={breadcrumbs}
        color="var(--xvia-color-primary-ligth)"
      />

      <Container className="xvia-catalog__container">
        <Box sx={{ paddingY: '2.5rem' }}>
          <Row gutter={40}>
            <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
              <BoxSquare
                title={name}
                description={striptags(description)}
                button={
                  isOnline ? (
                    <Button
                      children="Realizar serviço online"
                      variant="contained"
                      endIcon={<Logout />}
                      onClick={onServiceClick}
                    />
                  ) : null
                }
                infoCreate={[
                  {
                    icon: <CalendarMonth />,
                    text: 'há 12 minutos',
                  },
                  {
                    icon: <Person />,
                    text: 'Por: Michele Nunes',
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '2rem',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <IconText
                  icon={link ? <Person /> : <LocationOn />}
                  text={
                    link
                      ? 'Acessar Serviço Digital'
                      : 'Serviço realizado presencialmente'
                  }
                />
                {category?.name && (
                  <IconText icon={<AccountTree />} text={category.name} />
                )}
                {department?.shortName && department?.name && (
                  <IconText
                    icon={<Work />}
                    text={`${department.shortName} - ${department.name}`}
                  />
                )}
              </Box>
            </Col>
          </Row>
        </Box>
        <Row gutter={40}>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
            >
              <Accordion
                title="O que é este serviço"
                subTitle="(Clique para ampliar a resposta)"
                icon={<Person />}
              >
                {striptags(description)}
              </Accordion>

              {!!steps?.length && (
                <Accordion
                  title="Etapas para a realização deste serviço"
                  subTitle="(Clique para ampliar a resposta)"
                  icon={<FormatListBulleted />}
                >
                  <div className="xvia-catalog__info_section">
                    {steps?.map((step, index) => {
                      return (
                        <div className={'xvia-catalog__info_row'} key={step.id}>
                          {steps[index - 1]?.channel !== step?.channel && (
                            <div style={{ fontSize: 20 }}>
                              {step?.channel === 'online' ? (
                                <div
                                  className={'xvia-catalog__info_row_cannel'}
                                >
                                  Online{' '}
                                  <span>- Canal de atendimento digital</span>
                                </div>
                              ) : (
                                <div
                                  className={'xvia-catalog__info_row_cannel'}
                                >
                                  Presencial <span>- Unidades do governo</span>
                                </div>
                              )}
                            </div>
                          )}
                          <Step number={step.orderIndex} title={step?.name}>
                            <Paragraph>{step?.description}</Paragraph>
                            {renderHTML(step?.htmlContent)}
                            <div>
                              {!!step.documents.length && (
                                <div>
                                  <h3>Documentos necessários</h3>
                                  {step.documents.map((doc, docIndex) => {
                                    return (
                                      <div key={docIndex}>
                                        <Info text={doc} icon={'fa-check'} />
                                      </div>
                                    );
                                  })}{' '}
                                </div>
                              )}
                            </div>
                          </Step>
                        </div>
                      );
                    })}
                  </div>
                </Accordion>
              )}

              {!!info?.length && (
                <Accordion
                  title="Outras informações"
                  subTitle="(Clique para ampliar a resposta)"
                  icon={<InfoOutlined />}
                >
                  <div className="xvia-catalog__info_section">
                    {info?.map((info) => {
                      return (
                        <div style={{ marginBottom: 60 }} key={info.id}>
                          <Step icon={info?.icon} title={info?.title}>
                            {renderHTML(info?.text)}
                          </Step>
                        </div>
                      );
                    })}
                  </div>
                </Accordion>
              )}
            </Box>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Location departmentSections={departmentSections || []} />
          </Col>
        </Row>
      </Container>
      <ExtraSection>
        <TitleSection
          titleSettings={{
            title: 'Cartas de Serviços Semelhantes',
            colorTitle: '#202020',
            subtitle: 'Veja outras Cartas de Serviços semelhantes a essa',
            colorSubtitle: '#7B7B7B',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <MoreHorizIcon />,
            iconColor: '#9E9E9E',
            iconSize: '2x',
          }}
        />
        <Row gutter={40}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ServiceCard
              title="Consulta de Vagas na Rede Pública"
              description="Serviço que permite consultar a disponibilidade de vagas nas escolas da rede pública."
              onClick={() => {}}
              targets={[
                'SEDUC - Secretária da Estado de Educação',
                'Serviço presencial',
              ]}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ServiceCard
              title="Programas de Educação de Jovens e Adultos (EJA)"
              description="Oferta de educação para jovens e adultos que não concluíram o ensino básico na idade apropriada."
              onClick={() => {}}
              targets={[
                'SEDUC - Secretária da Estado de Educação',
                'Serviço online',
              ]}
            />
          </Col>
        </Row>
      </ExtraSection>
      <ContactUsContainer />
    </div>
  );
};
