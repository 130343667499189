import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { WebgateProvider } from '@xvia/webgate-connect';
import './icons';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';

const baseName = window.__APP_BASE__ || '/';

ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter basename={baseName}>
      <WebgateProvider portalUrl={process.env.PORTAL_URL as string}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </WebgateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
