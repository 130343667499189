import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const ContainerVideoPodcast = styled(MuiBox)`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 990px) {
    display: block;
  }
`;

export const ContainerVideo = styled(MuiBox)`
  width: 50%;
  margin: 0 50px 0 0;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const ContainerPodcast = styled(MuiBox)`
  width: 50%;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const Title = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;

  > h3 {
    margin: 0 0 0 15px;
    font-size: 18px;
    font-family: 'Roboto Serif';
    font-weight: 500;
  }

  > svg {
    color: #7b7b7b;
  }
`;
