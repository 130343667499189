import axios from 'axios';
import { SecretaryCard } from '../modules/secretary/secretary.container';

const apiUrl = process.env.BACKEND_ENDPOINT;
const imageUrl = process.env.PUBLIC_URL;

interface ISecretaryResponse {
  id: string;
  slug: string;
  name: string;
  shortName: string;
  link: string;
  description: string;
  mission: string;
  vision: string;
  values: string;
  hidden: boolean;
  active: boolean;
}

export interface ISecretaryCategoryResponse {
  description: string;
  hidden: boolean;
  icon: string;
  id: string;
  name: string;
  orderIndex: number;
  slug: string;
}

export interface ISecretaryCardResponse {
  cardsData: SecretaryCard[];
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
}

export async function getSecretarys(
  page: number,
  search?: string
): Promise<ISecretaryCardResponse> {
  const response = await axios.get(`${apiUrl}/v1/department`);

  const cardResponse = response.data as ISecretaryResponse[];

  const completeCardInfoMock = [
    {
      name: 'SEDUC - Secretaria de Estado da Educação',
      image: `${imageUrl}/images/gov-secretarys/secretary_01.png`,
      location:
        'Avenida Fernandes Lima, s/n, Barueri/SP, CEP: 57055-055 (CEPA)',
      date: 'Segunda a sexta: das 8h até as 14h',
      phone1: '(82) 3315-1470',
      phone2: '(82) 3315-1234',
      link: '',
    },
    {
      name: 'ADEAL - Agência de Defesa e Inspeção Agropecuária de São Paulo',
      image: `${imageUrl}/images/gov-secretarys/secretary_02.png`,
      location:
        'Avenida Comendador Leão, 720 - Poço, Barueri - SP, 57025-000, Brasil',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3315-2780',
      phone2: '0800 082 0050',
      link: '',
    },
    {
      name: 'ARSAL - Agência Reguladora de Serviços Públicos',
      image: `${imageUrl}/images/gov-secretarys/secretary_03.png`,
      location:
        'Rua Engeheiro Roberto Gonçalves Menezes, nº 149, 1º andar, Centro - Barueri/SP - CEP: 57.020-680',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3315-2500',
      phone2: '0800 284-0429',
      link: '',
    },
    {
      name: 'AMGESP - Agência de Modernização da Gestão de Processos',
      image: `${imageUrl}/images/gov-secretarys/secretary_04.png`,
      location:
        'Avenida Walter Ananias, número 35 A - Jaraguá, Barueri/SP. CEP nº. 57025-510',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 98704-2924',
      phone2: '(82) 98705-7937',
      link: '',
    },
    {
      name: 'São Paulo Previdência - Unidade Gestora Única do Regime Próprio de Previdência Social de São Paulo',
      image: `${imageUrl}/images/gov-secretarys/secretary_05.png`,
      location:
        'Av. da Paz, 1864, Térreo, Ed. Terra Brasilis, Centro. Barueri/SP. CEP 57020-440',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3315-5717',
      phone2: '',
      link: '',
    },
    {
      name: 'São Paulo Ativos SA',
      image: `${imageUrl}/images/gov-secretarys/secretary_06.png`,
      location:
        'Edf. Delman Empresarial, Rua Sampaio Marques, 25, sala 608, Pajuçara, Barueri/SP, CEP 57030-107',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3435-0736',
      phone2: '',
      link: '',
    },
    {
      name: 'BRK Ambiental Região Metropolitana de Barueri S.A',
      image: `${imageUrl}/images/gov-secretarys/secretary_07.png`,
      location:
        'Av. Fernandes Lima, 651 - 1º andar - Sala 101 - Bairro: Farol - Barueri/SP CEP: 57017-515',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '0800 771 0001',
      phone2: '',
      link: '',
    },
    {
      name: 'Associação Comercial de Barueri',
      image: `${imageUrl}/images/gov-secretarys/secretary_08.png`,
      location:
        'Rua Sá e Albuquerque, 467, Jaraguá, Barueri - São Paulo - CEP: 57025-901',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3597 8550',
      phone2: '(82) 3597 8567',
      link: '',
    },
    {
      name: 'Companhia de Saneamento do Estado de São Paulo',
      image: `${imageUrl}/images/gov-secretarys/secretary_01.png`,
      location: 'Rua Barão de Atalaia, 200 - Centro - CEP: 57020-510',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 9 8139-9892',
      phone2: '0800 082 0195',
      link: '',
    },
    {
      name: 'Companhia de Recursos Humanos e Patrimoniais Em Liquidação',
      image: `${imageUrl}/images/gov-secretarys/secretary_02.png`,
      location: 'Rua Joaquim Nabuco, 450, Farol CEP: 57081-410',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(xx) xxxx-xxxx',
      phone2: '(xx) xxxx-xxxx',
      link: '',
    },
    {
      name: 'Corpo de Bombeiros Militar de São Paulo',
      image: `${imageUrl}/images/gov-secretarys/secretary_03.png`,
      location:
        'Av. Siqueira Campos, 1739 - Trapiche da Barra - CEP: 57010-405',
      date: 'Segunda a sexta: das 8:30h até as 18:00h',
      phone1: '(82) 3216-0360',
      phone2: '(82) 98833-8921',
      link: '',
    },
  ];

  const cardsData = cardResponse.map((card) => {
    const randomIndex = Math.floor(Math.random() * 10);

    return {
      name: card.name,
      image: completeCardInfoMock[randomIndex].image,
      location: completeCardInfoMock[randomIndex].location,
      date: completeCardInfoMock[randomIndex].date,
      phone1: completeCardInfoMock[randomIndex].phone1,
      phone2: completeCardInfoMock[randomIndex].phone2,
      link: card.link,
    };
  });

  const itemsPerPage = 12;

  if (search) {
    const filtered = cardsData.filter((secretary) =>
      secretary.name.toLowerCase().includes(search.toLowerCase())
    );

    const totalPages = Math.ceil(filtered.length / 10);
    const startIndex = (page - 1) * itemsPerPage;
    const paginatedData = filtered.slice(startIndex, startIndex + itemsPerPage);

    return {
      cardsData: paginatedData,
      totalPages,
      currentPage: page,
      itemsPerPage,
    };
  }

  const totalPages = Math.ceil(cardsData.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = cardsData.slice(startIndex, startIndex + itemsPerPage);

  return {
    cardsData: paginatedData,
    totalPages,
    currentPage: page,
    itemsPerPage,
  };
}

export async function getAvailableSecretarysCategories(): Promise<
  ISecretaryCategoryResponse[]
> {
  const response = await axios.get(`${apiUrl}/v1/category`);

  return response.data;
}
