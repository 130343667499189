import { NewsItem } from '../modules/home/gov-news/tab-news-content/all-content/all-content.container';

export const reorganizeNews = <T extends Partial<NewsItem>>(
  news: Record<string, T[]>,
  itemsPerGroup = 2
): Record<number, T[]> => {
  const newNews: Record<number, T[]> = {};
  let counter = 1;
  let tempArray: T[] = [];

  for (const key in news) {
    for (const item of news[key]) {
      tempArray.push(item);
      if (tempArray.length === itemsPerGroup) {
        newNews[counter] = [...tempArray];
        tempArray = [];
        counter++;
      }
    }
  }

  if (tempArray.length > 0) {
    newNews[counter] = [...tempArray];
  }

  return newNews;
};
