import React, { FC, useCallback, useEffect, useState } from 'react';
import { getGalleryNews } from '../../../../../apis/news';
import { GalleryContent } from './gallery-content';
import { NewsMediaItem } from '../all-content/all-content.container';

export type GalleryNewsItem = Omit<NewsMediaItem, 'imageVideo'>;
export type GalleryNewsContent = Record<string, GalleryNewsItem[]>;

export const GalleryContentContainer: FC = () => {
  const [galleryNewsList, setGalleryNewsList] = useState<GalleryNewsContent>(
    {}
  );
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getNewsServer = useCallback(() => {
    getGalleryNews()
      .then(setGalleryNewsList)
      .catch((err) => {
        console.log(err);
      });
  }, [setGalleryNewsList]);

  useEffect(() => {
    Object.keys(galleryNewsList).length === 0 && getNewsServer();

    if (Object.keys(galleryNewsList).length > 0) {
      const total = Object.keys(galleryNewsList).length;
      setTotalPages(total);
    }
  }, [galleryNewsList, getNewsServer, setTotalPages]);

  return (
    <GalleryContent
      galleryNewsList={galleryNewsList}
      page={page}
      totalPages={totalPages}
      handleChange={handleChange}
    />
  );
};
