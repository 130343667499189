import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import MuiTypography from '@mui/material/Typography';

export const OpenDrawerFilterButton = styled(MuiButton)`
  text-transform: capitalize;
  font-size: 18px;
`;

export const ContainerPageActions = styled(MuiBox)`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const SearchFilterBtn = styled(MuiBox)`
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;

  > button {
    background: transparent;
    border: none;
    box-shadow: none;
    color: var(--xvia-color-darkness-blue);
  }

  @media (max-width: 800px) {
    justify-content: flex-end;
    margin: 20px 0;
  }
`;

export const ContainerPagination = styled(MuiBox)`
  justify-content: center;
  margin: 50px 0;
  align-items: center;
  display: flex;
`;

export const FilterTitle = styled(MuiTypography)`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto Serif', serif;
  margin: 10px 0;
`;
