import React, { FC } from 'react';
import { Box, Grid, Typography, Paper, IconButton } from '@mui/material';

interface ContactInfoProps {
  icon: React.ReactNode;
  label: string;
  value: string;
}

const ContactInfo: FC<ContactInfoProps> = ({ icon, label, value }) => (
  <Grid item xs={12} sm={6}>
    <Paper className="xvia-contact-us__card">
      <IconButton className="xvia-contact-us__card-icon">{icon}</IconButton>
      <Box>
        <div className="xvia-contact-us__card-label">{label}</div>
        <Typography variant="body1" className="xvia-contact-us__card-value">
          {value}
        </Typography>
      </Box>
    </Paper>
  </Grid>
);

export default ContactInfo;
