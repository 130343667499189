import { createTheme } from '@mui/material';

export const designTokens = {
  palette: {
    primary: {
      main: '#005EA9',
      hover: '#FF891B',
    },
    secondary: {
      main: '#FF891B',
    },
    neutral: {
      checkbox: '#BBBBBB',
    },
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: designTokens.palette.primary.main,
    },
    secondary: {
      main: designTokens.palette.secondary.main,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: designTokens.palette.primary.hover,
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: designTokens.palette.primary.hover,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: designTokens.palette.neutral.checkbox,
          '&.Mui-checked': {
            color: designTokens.palette.primary.hover,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});
