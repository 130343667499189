import React, { FC, Fragment } from 'react';
import Stack from '@mui/material/Stack';

import { Pagination } from '../../../../../components';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';
import EastIcon from '@mui/icons-material/East';
import { mathIconCategory } from '../../../../../util/matchAplicationIcons';
import {
  ActionsInCard,
  Box,
  BoxContainerCards,
  Button,
  Card,
  CardActions,
  CardBigger,
  CardContainerImg,
  CardContainerInfo,
  CardContent,
  CardContentBigger,
  CardContentBiggerImg,
  CardContentBiggerText,
  CardIconsInfo,
  CardText,
  CardTitle,
  ContainerCards,
  ContainerCardsBigger,
  ContainerPagination,
  Grid,
  Typography,
} from './style';

export interface MaterialItem {
  title: string;
  text: string;
  category: string;
  date: string;
  image: string;
  imageAlt: string;
}

export type IMaterialContent = Record<string, MaterialItem[]>;

export interface MaterialContentProps {
  newsList: IMaterialContent;
  totalPages: number;
  handleChange: any;
  page: number;
}

export const MaterialContent: FC<MaterialContentProps> = ({
  newsList,
  page,
  totalPages,
  handleChange,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ContainerCards>
        <ContainerCardsBigger>
          {Object.keys(newsList).length > 0 && (
            <>
              {newsList[`${page}`].map((item, index) => (
                <Fragment key={index}>
                  {index === 0 && (
                    <CardBigger sx={{ minWidth: 275 }}>
                      <CardContentBigger>
                        <CardContentBiggerImg>
                          <img src={item.image} alt={item.imageAlt} />
                        </CardContentBiggerImg>
                        <CardContentBiggerText>
                          <Box>
                            <CardIconsInfo>
                              <TodayIcon />
                              <Typography variant="body2">
                                {item.date}
                              </Typography>
                            </CardIconsInfo>
                            <CardIconsInfo>
                              {mathIconCategory[item.category] || (
                                <CategoryIcon />
                              )}
                              <Typography variant="body2">
                                {item.category}
                              </Typography>
                            </CardIconsInfo>
                          </Box>

                          <CardTitle>{item.title}</CardTitle>
                          <CardText>{item.text}</CardText>
                        </CardContentBiggerText>
                      </CardContentBigger>
                      <CardActions>
                        <Button size="small" variant="contained">
                          Ler completa
                          <EastIcon />
                        </Button>
                      </CardActions>
                    </CardBigger>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </ContainerCardsBigger>
        <BoxContainerCards>
          {Object.keys(newsList).length > 0 && (
            <>
              {newsList[`${page}`].map((item, index) => (
                <Fragment key={index}>
                  {index !== 0 && (
                    <Grid item xs={12}>
                      <Card
                        sx={{ minWidth: 275 }}
                        style={{
                          margin:
                            index === Object.keys(newsList).length
                              ? '0'
                              : '0 0 20px 0',
                        }}
                      >
                        <CardContent>
                          <CardContainerImg>
                            <img src={item.image} alt={item.imageAlt} />
                          </CardContainerImg>
                          <CardContainerInfo>
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                              <CardIconsInfo>
                                <TodayIcon />
                                <Typography variant="body2">
                                  {item.date}
                                </Typography>
                              </CardIconsInfo>
                              <CardIconsInfo>
                                {mathIconCategory[item.category] || (
                                  <CategoryIcon />
                                )}
                                <Typography variant="body2">
                                  {item.category}
                                </Typography>
                              </CardIconsInfo>
                            </Stack>

                            <CardTitle>{item.title}</CardTitle>

                            <CardText>{item.text}</CardText>

                            <ActionsInCard>
                              <Button size="small" variant="contained">
                                Ler completa
                                <EastIcon />
                              </Button>
                            </ActionsInCard>
                          </CardContainerInfo>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </BoxContainerCards>
      </ContainerCards>

      <ContainerPagination>
        <Pagination count={totalPages} onChange={handleChange} page={page} />
      </ContainerPagination>
    </Box>
  );
};
