import React, { useEffect, useState } from 'react';
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  useTheme,
  Stack,
  InputBase,
} from '@mui/material';

import {
  ExpandMore,
  KeyboardDoubleArrowDown,
  LocationCity,
  LocationOn,
  Map,
  Search,
  WatchLater,
} from '@mui/icons-material';

interface Department {
  id: string;
  slug: string;
  name: string;
  shortName: string;
  mission: string;
  vision: string;
  values: string;
  hidden: boolean;
  active: boolean;
}

interface DepartmentSections {
  id?: string;
  name: string;
  address: string;
  postalCode: string;
  district: string;
  city: string;
  state: string;
  countryCode: string;
  openingTime: string;
  mapUrl: string;
  active?: boolean;
  complement?: string;
  department: Department;
}

interface LocationProps {
  departmentSections: DepartmentSections[];
}

export default function Location({
  departmentSections,
  ...rest
}: LocationProps) {
  const theme = useTheme();
  const [search, setSearch] = useState<string>();
  const [filteredSections, setFilteredSections] =
    useState<DepartmentSections[]>(departmentSections);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  useEffect(() => {
    setFilteredSections(departmentSections);
  }, [departmentSections]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    const filtered = departmentSections.filter((section) =>
      section.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSections(filtered);
  };

  return (
    <div>
      <Box marginBottom={'1.5rem'}>
        <Typography
          fontWeight={500}
          lineHeight={1}
          color="var(--xvia-color-grey-100)"
          fontFamily="'Roboto Serif', serif"
          fontSize="26px"
        >
          Onde pode ser realizado:
        </Typography>
        <Stack>
          <div className="xvia-location__search-container">
            <Search />
            <InputBase
              value={search}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Busque localização..."
              className="xvia-location__search-input"
            />
          </div>
        </Stack>
      </Box>
      {filteredSections.length > 0 ? (
        <>
          {filteredSections.map((section) => (
            <MUIAccordion
              key={section.name}
              {...rest}
              sx={{
                padding: '8px',
                transition: 'all 0.3s ease',
                margin: '0px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '&:hover': {
                  '& .icon': {
                    '& svg': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                '&.Mui-expanded': {
                  margin: '0px',
                  borderColor: theme.palette.primary.main,
                  borderWidth: '1px',
                  '& .icon': {
                    '& svg': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                '& .css-o4b71y-MuiAccordionSummary-content': {
                  margin: '0px !important',
                },
              }}
              onMouseEnter={() => setHoveredItem(section.name)}
              onMouseLeave={() => setHoveredItem(null)}
              onChange={(_event, isExpanded) =>
                setExpandedItem(isExpanded ? section.name : null)
              }
              aria-expanded={expandedItem === section.name}
            >
              <AccordionSummary
                expandIcon={
                  hoveredItem === section.name ||
                  expandedItem === section.name ? (
                    <KeyboardDoubleArrowDown />
                  ) : (
                    <ExpandMore />
                  )
                }
                aria-controls={`${section.name}-panel-content`}
                id={`${section.name}-panel1-header`}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      fontWeight={expandedItem === section.name ? 600 : 500}
                      color="var(--xvia-color-grey-100)"
                      fontFamily="Roboto"
                      fontSize="14px"
                    >
                      {section.name}
                    </Typography>
                    <Stack direction={'row'} padding={0} alignItems={'start'}>
                      <LocationOn
                        sx={{
                          padding: '0px',
                          width: '15px',
                          marginLeft: '-4px',
                          color: 'var(--xvia-color-secondary)',
                        }}
                      />
                      <Typography
                        fontWeight={expandedItem === section.name ? 500 : 400}
                        color="var(--xvia-color-grey-100)"
                        fontFamily="Roboto"
                        fontSize="14px"
                        marginTop="2px"
                      >
                        {section.address} {`- ${section.complement}`}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                id={`${section.name}-panel-content`}
                aria-labelledby={`${section.name}-panel1-header`}
                sx={{ paddingY: '0px' }}
              >
                <Stack direction={'row'} padding={0} alignItems={'start'}>
                  <LocationCity
                    sx={{
                      padding: '0px',
                      width: '15px',
                      marginLeft: '-4px',
                      color: 'var(--xvia-color-secondary)',
                    }}
                  />
                  <Typography
                    fontWeight={expandedItem === section.name ? 500 : 400}
                    color="var(--xvia-color-grey-100)"
                    fontFamily="Roboto"
                    fontSize="14px"
                    marginTop="2px"
                  >
                    {section.district}
                  </Typography>
                </Stack>
                <Stack direction={'row'} padding={0} alignItems={'start'}>
                  <Map
                    sx={{
                      padding: '0px',
                      width: '15px',
                      marginLeft: '-4px',
                      color: 'var(--xvia-color-secondary)',
                    }}
                  />
                  <Typography
                    fontWeight={expandedItem === section.name ? 500 : 400}
                    color="var(--xvia-color-grey-100)"
                    fontFamily="Roboto"
                    fontSize="14px"
                    marginTop="2px"
                  >
                    {section.city} {`- ${section.state}`}
                  </Typography>
                </Stack>
                {section.openingTime && (
                  <Stack direction={'row'} padding={0} alignItems={'center'}>
                    <WatchLater
                      sx={{
                        padding: '0px',
                        width: '15px',
                        marginLeft: '-4px',
                        color: 'var(--xvia-color-secondary)',
                      }}
                    />
                    <Typography
                      fontWeight={expandedItem === section.name ? 500 : 400}
                      color="var(--xvia-color-grey-100)"
                      fontFamily="Roboto"
                      fontSize="14px"
                    >
                      {section.openingTime}
                    </Typography>
                  </Stack>
                )}
                {section.mapUrl && (
                  <iframe
                    src={section.mapUrl}
                    title={`Mapa da localização da unidade ${section.name}`}
                    aria-label={`Mapa da localização da unidade ${section.name}`}
                    className="xvia-location__map"
                  />
                )}
              </AccordionDetails>
            </MUIAccordion>
          ))}
        </>
      ) : (
        <Typography
          color="var(--xvia-color-grey-100)"
          fontFamily="Roboto"
          fontSize="14px"
          marginTop="2px"
          textAlign={'center'}
        >
          Nenhuma unidade disponível para este serviço
        </Typography>
      )}
    </div>
  );
}
