import { FC } from 'react';
import {
  ServiceCard,
  ServiceCardProps,
} from '../../components/cards/service-card';

interface FilteredServicesPanelProps {
  serviceCards: ServiceCardProps[];
  animationClass: string;
}

export const FilteredServicesPanel: FC<FilteredServicesPanelProps> = ({
  serviceCards: serviceCardArray,
  animationClass,
}) => (
  <div className={animationClass}>
    <hr />
    <div className="xvia-filtered-services-panel">
      {serviceCardArray.map((serviceCardProps, index) => (
        <ServiceCard key={index} {...serviceCardProps} />
      ))}
    </div>
    <div className="xvia-filtered-services-panel__container__footer">
      <button>Ver todas</button>
    </div>
  </div>
);
