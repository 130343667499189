import { FC, useEffect, useState } from 'react';
import { getTopCatalog } from '../../apis/relevant-catalog';
import { search } from '../../apis/search-catalog';
import { ServiceCardProps } from '../../components/cards/service-card';
import { FilteredServicesPanel } from './filtered-services-panel';
import { useHistory } from 'react-router-dom';

export enum FilterEnum {
  MAIN = 'main',
  CAR = 'transito-veiculos-e-habilitacao',
  HEALTH = 'saude-e-vigilancia-sanitaria',
  SECURITY = 'seguranca-publica',
  RESEARCH = 'educacao-e-pesquisa',
}

export interface FilteredServicesPanelProps {
  filter: FilterEnum;
  animationClass: string;
  timeoutId: NodeJS.Timeout | number | null;
}
export const FilteredServicesPanelContainer: FC<FilteredServicesPanelProps> = ({
  filter,
  animationClass,
  timeoutId,
}) => {
  const [serviceCards, setServiceCards] = useState<ServiceCardProps[]>([]);
  const history = useHistory();

  const chooseFilterFunction = (filter: string) => {
    return Object.values<string>(FilterEnum).includes(filter) &&
      filter !== FilterEnum.MAIN
      ? search({ category: [filter] })
      : getTopCatalog();
  };

  useEffect(() => {
    const getData = async () => {
      const result = await chooseFilterFunction(filter);
      setServiceCards(
        result
          .map<ServiceCardProps>(
            ({ targets, title, category, description, slug }) => ({
              targets,
              title,
              category,
              description,
              onClick: () => {
                history.push(`/carta-de-servicos/${slug}`);
              },
            })
          )
          .slice(0, 4)
      );
    };
    getData();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [filter, history, timeoutId]);

  return (
    <FilteredServicesPanel
      serviceCards={serviceCards}
      animationClass={animationClass}
    />
  );
};
